<template>
  <div class="outer">
    <span style="width: 60px;margin-right: 20px; text-align: right;">{{ dataLabel }}</span>
    <progress max="100" :value="value" class="progress-main" />
    <span style="width: 60px; margin-left: 20px;">{{ value }}</span>
  </div>
</template>
<script>
export default {
  props: ['value', 'dataLabel']
}
</script>
<style lang="scss" scoped>
@import '@/styles/_mixins';

$progressBarColour: #F2F2F2;
$progressValueColour: #17BAB3;

.outer {
  padding: 8px 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  span {
    font-size: 12px;
    background-color: rgba(255, 255, 255, 0);
    font-family: 'Montserrat';
    font-weight: bold;
    color: #6e6e6e;
  }
}

.progress-main {
  background-color: red;
  display: block;
  width: 300px;
  height: 20px;
}
.progress-main::-moz-progress-bar {
  // background: $progressValueColour;
  background-image: linear-gradient(90deg, #38a474 0%, #87e370 100%);
}
.progress-main::-webkit-progress-bar {
  background: $progressBarColour;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2) inset;
}
.progress-main::-webkit-progress-value {
  // background: $progressValueColour;
  background-image: linear-gradient(90deg, #38a474 0%, #87e370 100%);
}
.progress-main::-ms-fill {
  background: $progressValueColour;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2) inset;
}
@include breakpoint-phone {
  .progress-main {
    width: 150px;
  }
}
</style>

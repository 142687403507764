<template>
  <div class="outer-container">
    <div
      v-for="(item, index) in messages"
      :key="index"
      class="message-container"
    >
      <span class="time">{{ item.time }}</span>
      <level-label :level="item.level"></level-label>
      <span class="message">{{ item.message }}</span>
    </div>
  </div>
</template>
<script>
import LevelLabel from '@/components/LevelLabel/LevelLabel'
export default {
  components: { LevelLabel },
  data() {
    return {
      messages: [
        {
          time: '2020-12-31 12:34:56',
          level: 'info',
          message: 'Close guard ->TC quit!'
        },
        {
          time: '2020-12-31 12:34:56',
          level: 'danger',
          message: 'Close guard ->TC quit!'
        },
        {
          time: '2020-12-31 12:34:56',
          level: 'primary',
          message: 'Close guard ->TC quit!'
        },
        {
          time: '2020-12-31 12:34:56',
          level: 'inverse',
          message: 'Close guard ->TC quit!'
        },
        {
          time: '2020-12-31 12:34:56',
          level: 'warning',
          message: 'Close guard ->TC quit!'
        },
        {
          time: '2020-12-31 12:34:56',
          level: 'success',
          message: 'Close guard ->TC quit!'
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.outer-container {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  padding: 26px 35px;
  border-radius: 10px;

  .message-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    margin: 5px 0;

    .time {
      font-size: 12px;
      font-family: 'Montserrat Medium', 'Montserrat Regular', 'Montserrat';
      font-weight: 500;
      color: #333333;
      line-height: normal;
      margin-right: 30px;
    }
    .message {
      margin-left: 30px;
      font-size: 12px;
      font-family: 'Montserrat';
      color: #6e6e6e;
      line-height: normal;
    }
  }
}
</style>

<template>
  <nav class="outer-container">
    <div class="logo" @click="$router.push({ name: 'home' })"></div>
    <!-- <div style="flex-grow: 1"></div> -->
    <ul class="item-container">
      <li class="item">
        <router-link tag="a" active-class="active" to="/device">
          設備詳情
        </router-link>
      </li>
      <li class="item">
        <a href="#">
          <span>設備組件診斷</span>
        </a>
      </li>
      <li class="item">
        <a href="#">
          <span>設備壽命估算</span>
        </a>
      </li>
      <li class="item">
        <a href="#">
          <span>消耗品監控</span>
        </a>
      </li>
      <li class="item">
        <a href="#">
          <span>能耗監控</span>
        </a>
      </li>
      <li class="item">
        <a href="#">
          <span>稼動率監控</span>
        </a>
      </li>
      <li class="item">
        <a href="#">
          <span>資料分析</span>
        </a>
      </li>
    </ul>
    <div style="flex-grow: 1"></div>
    <ul class="icon-container">
      <li class="icon">
        <a href="#">
          <font-awesome-icon :icon="['fas', 'globe-asia']" size="lg" />
        </a>
      </li>
      <li class="icon">
        <a href="#">
          <font-awesome-icon :icon="['fas', 'question-circle']" size="lg" />
        </a>
      </li>
      <li class="icon">
        <a href="#">
          <font-awesome-icon :icon="['fas', 'search']" size="lg" />
        </a>
      </li>
      <li class="icon">
        <a href="#">
          <font-awesome-icon :icon="['fas', 'sign-out-alt']" size="lg" />
        </a>
      </li>
      <li class="icon bar">
        <a href="#">
          <font-awesome-icon :icon="['fas', 'bars']" size="lg" />
        </a>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  created() {
    // console.log(this.$route.name)
  }
}
</script>
<style lang="scss" scoped>
// $navHeight: calc(30px + 30 * ((100vh -300px) / (1080 - 300)));
// $logoWidth: 180px;
// $itemWidth: 150px;
// $itemWidth: calc(100px + 50 * ((100vw - 1560px) / (1920 - 1560)));
$iconWidth: 50px;
$activeColor: #4d7fff;
$fontSize: calc(12px + (16 - 12) * ((100vw - 180px) / (1560 - 180)));

li a {
  display: flex;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  font-size: $fontSize;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #4d7fff;
  }
}

.outer-container {
  // position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  height: 90px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  background: rgba(0, 0, 0, 0.8);
  z-index: 2;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  .logo {
    background: url('~@/assets/images/logo.png') no-repeat left/cover;
    height: 100%;
    min-width: 150px;
    margin: 0 40px;
    cursor: pointer;
    box-sizing: border-box;
    padding: 2px;
  }

  ul.item-container {
    // border: 2px green solid;
    height: 90px;
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    list-style: none;

    .item {
      width: 150px;

      .active {
        background-color: $activeColor;
      }
    }
  }

  ul.icon-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    // border: 2px red solid;
    // width: 100%;
    margin-right: 40px;
    height: 90px;
    list-style: none;

    .icon {
      width: $iconWidth;
      &.active {
        background-color: $activeColor;
      }
    }
    .bar {
      display: none;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .outer-container .logo {
    min-width: 120px;
    margin: 0 20px;
  }
  .outer-container ul.item-container .item {
    min-width: 120px;
  }
}
@media only screen and (max-width: 415px) {
  .outer-container {
    height: 60px;
    .logo {
      min-width: 90px;
      margin: 0 20px;
    }
    ul.item-container {
      height: 60px;
      .item {
        min-width: 90px;
      }
    }
    ul.icon-container {
      height: 60px;
    }
  }
}
</style>

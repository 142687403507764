<template>
  <div class="axis-outer-container">
    <span class="title">Axial Load</span>
    <section>
      <progress-bar data-label="X" value="1"></progress-bar>
      <progress-bar data-label="Y" value="5"></progress-bar>
      <progress-bar data-label="V" value="1"></progress-bar>
      <progress-bar data-label="Z" value="48"></progress-bar>
      <progress-bar data-label="A" value="0"></progress-bar>
      <progress-bar data-label="C" value="0"></progress-bar>
    </section>
    <span class="title">Spindle</span>
    <section>
      <progress-bar data-label="Load" value="0"></progress-bar>
      <progress-bar data-label="Speed" value="0.005"></progress-bar>
    </section>
  </div>
</template>
<script>
import ProgressBar from '@/components/ProgressBar/ProgressBar'
export default {
  components: { ProgressBar }
}
</script>
<style lang="scss" scoped>
@import '@/styles/_mixins';

.axis-outer-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 480px;
  background-color: #ffffff;
  padding: 26px 35px;
  border-radius: 10px;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.15);

  .title {
    font-size: 14px;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: 'Montserrat Medium', 'Montserrat Regular', 'Montserrat';
    font-weight: 500;
    color: #333333;
    text-align: left;
    line-height: 20px;
    margin-bottom: 20px;
  }
}

@include breakpoint-desktop2() {
  .axis-outer-container {
    width: 450px;
  }
}
@include breakpoint-desktop1() {
  .axis-outer-container {
    width: 420px;
  }
}
@include breakpoint-laptop2 {
  .axis-outer-container {
    width: 400px;
  }
}
@include breakpoint-laptop1 {
  .axis-outer-container {
    width: 320px;
    padding: 26px 20px;
  }
}
@include breakpoint-phone-plus {
  .axis-outer-container {
    min-width: 340px;
    padding: 26px 20px;
  }
}
@include breakpoint-phone {
  .axis-outer-container {
    width: 320px;
    padding: 26px 10px;
  }
}
</style>

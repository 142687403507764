<template>
  <div class="status-outerContainer">
    <div class="innerContainer" v-for="i in 3" :key="i">
      <template v-for="(item, j) in list[i - 1]">
        <span :class="item.checked ? 'checked' : 'unchecked'" :key="j">
          <font-awesome-icon
            fas
            :icon="item.checked ? 'check-circle' : 'times-circle'"
            size="lg"
          />
          {{ item.msg }}
        </span>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        [
          {
            msg: 'Feed Axis',
            checked: true
          },
          {
            msg: 'Spindle',
            checked: false
          },
          {
            msg: 'LUBE1',
            checked: false
          }
        ],
        [
          {
            msg: 'LAMP',
            checked: true
          },
          {
            msg: 'Cooling Pump',
            checked: false
          },
          {
            msg: 'LUBE2',
            checked: false
          }
        ],
        [
          {
            msg: 'GUARD',
            checked: true
          },
          {
            msg: 'CTS Pump',
            checked: false
          },
          {
            msg: 'Chip Conveyor',
            checked: false
          }
        ]
      ]
    }
  },
  created() {},
  computed: {
    listName(num) {
      return `list${num}`
    }
  }
}
</script>
<style lang="scss" scoped>
.status-outerContainer {
  display: flex;
  flex-direction: row;
  align-content: stretch;
  justify-content: space-between;

  .innerContainer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
  }

  span {
    font-family: 'Montserrat';
    font-size: 10px;
    margin-right: 12px;
    margin-bottom: 5px;

    &.checked {
      color: #6e6e6e;
      svg {
        color: #4d7fff;
      }
    }

    &.unchecked {
      color: #cbcbcb;
      svg {
        color: #cbcbcb;
      }
    }
  }
}
</style>

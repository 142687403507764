<template>
  <div class="outer-container">
    <div class="title-container">
      <label class="status">Machining</label>
      <span class="title">Application Center AC-103</span>
    </div>
    <div class="main-container">
      <info style="margin-bottom: 20px;"></info>
      <axis style="margin-bottom: 20px;"></axis>
      <status style="margin-bottom: 20px;"></status>
    </div>
    <!-- <div class="title-container">
      <span class="title">Message</span>
    </div> -->
    <message></message>
  </div>
</template>
<script>
import Info from './components/Info'
import Axis from './components/Axis'
import Message from './components/Message'
import Status from './components/Status'

export default {
  components: { Info, Axis, Message, Status }
}
</script>
<style lang="scss" scoped>
@import '@/styles/_mixins';
.outer-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  .title-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;

    .status {
      font-size: 14px;
      border-radius: 30px;
      background-color: #65c872;
      font-family: 'Montserrat';
      font-weight: bold;
      color: #ffffff;
      line-height: normal;
      padding: 5px 20px;
      margin-right: 10px;
    }

    .title {
      font-size: 32px;
      font-family: 'Montserrat Bold', 'Montserrat Regular', 'Montserrat';
      font-weight: 700;
      color: #333333;
      line-height: normal;
    }
  }

  .main-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    margin: 30px 0;
    flex-wrap: wrap;
  }
}
@include breakpoint-phone-plus {
  .outer-container .title-container {
    .title,
    .status {
      font-size: 14px;
    }
  }
}
@include breakpoint-phone {
  .outer-container .title-container {
    .title,
    .status {
      font-size: 14px;
    }
  }
}
</style>

<template>
  <div>
    <div class="container p-5 my-3">
      <h2>home page</h2>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Home'
}
</script>
<style lang="scss" scoped>

</style>

<template>
  <div>
    <h2>設備狀態</h2>
    <div class="outer-wrapper">
      <card></card>
      <card></card>
      <card></card>
      <card></card>
      <card></card>
      <card></card>
      <card></card>
    </div>
  </div>
</template>
<script>
import Card from './components/Card'

export default {
  components: { Card }
}
</script>
<style lang="scss" scoped>
.outer-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 30px;
  width: 100%;
  // border: 2px red solid;
}
</style>

<template>
  <div class="status-outer-container">
    <div class="title">Status</div>
    <div class="inner-container">
      <div v-for="(item, index) in list" :key="index" class="item-container">
        <font-awesome-icon
          :class="item.checked ? 'checked' : 'unchecked'"
          fas
          :icon="item.checked ? 'check-circle' : 'times-circle'"
          size="3x"
        />
        <span :class="item.checked ? 'checked' : 'unchecked'">{{
          item.msg
        }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        {
          msg: 'Feed Axis',
          checked: true
        },
        {
          msg: 'LAMP',
          checked: true
        },
        {
          msg: 'GUARD',
          checked: true
        },
        {
          msg: 'Spindle',
          checked: false
        },
        {
          msg: 'Cooling Pump',
          checked: false
        },
        {
          msg: 'CTS Pump',
          checked: false
        },
        {
          msg: 'LUBE1',
          checked: false
        },

        {
          msg: 'LUBE2',
          checked: false
        },

        {
          msg: 'Chip Conveyor',
          checked: false
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/_mixins';
// $itemWidth: calc(90px + (120 - 90) * ((100vw - 1440px) / (1920 - 1440)));

.status-outer-container {
  display: flex;
  flex-direction: column;
  width: 480px;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  border-radius: 10px;
  font-size: 14px;

  .title {
    font-size: 14px;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: 'Montserrat Medium', 'Montserrat Regular', 'Montserrat';
    font-weight: 500;
    color: #333333;
    text-align: left;
    line-height: 20px;
    margin-bottom: 20px;
    padding: 26px 35px 0px;
  }

  .inner-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 15px 35px 0px;

    .item-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 5px 2px;
      width: 120px;

      span {
        font-size: 14px;
        margin-top: 8px;
        background-color: rgba(255, 255, 255, 0);
        box-sizing: border-box;
        font-family: 'Montserrat';
        color: #6e6e6e;
        line-height: normal;

        &.checked {
          color: #6e6e6e;
        }

        &.unchecked {
          color: #cbcbcb;
        }
      }
      svg {
        &.checked {
          color: #4d7fff;
        }

        &.unchecked {
          color: #cbcbcb;
        }
      }
    }
  }
}

@include breakpoint-desktop2() {
  .status-outer-container {
    width: 450px;
    .inner-container .item-container {
      width: 110px;
    }
  }
}
@include breakpoint-desktop1() {
  .status-outer-container {
    width: 420px;
    .inner-container .item-container {
      width: 110px;
    }
  }
}
@include breakpoint-laptop2 {
  .status-outer-container {
    width: 400px;

    .inner-container .item-container {
      width: 100px;
    }
  }
}
@include breakpoint-laptop1 {
  .status-outer-container {
    width: 320px;
    font-size: 13px;

    .inner-container {
      padding: 10px 10px;
      .item-container {
        width: 95px;
        span {
          font-size: 13px;
        }
      }
    }
  }
}
@include breakpoint-phone-plus {
  .status-outer-container {
    min-width: 340px;
    font-size: 12px;

    .inner-container {
      padding: 10px 10px;
      .item-container {
        width: 95px;
        span {
          font-size: 12px;
        }
      }
    }
  }
}
@include breakpoint-phone {
  .status-outer-container {
    width: 320px;
    font-size: 12px;

    .inner-container {
      padding: 10px 10px;
      .item-container {
        width: 95px;
        span {
          font-size: 12px;
        }
      }
    }
  }
}
</style>

<template>
  <div id="app">
    <nav-link></nav-link>
    <router-view class="app-container" />
  </div>
</template>
<script>
import NavLink from '@/components/NavLink/NavLink.vue'

export default {
  components: {
    NavLink
  }
}
</script>
<style lang="scss">
@import "@/styles/app";
@import "@/styles/theme";
</style>

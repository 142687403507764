<template>
  <div class="info-outer-container">
    <div class="img"></div>
    <div class="inner-container">
      <div class="label-container">
        <span>Model</span>
        <span>Serial Number</span>
        <span>Controller</span>
        <span>NC Software</span>
        <span>NC Program</span>
        <span>Since</span>
        <span>Operator</span>
        <span>Tool Type</span>
        <span>Tool Number</span>
        <span>Radius</span>
        <span>Length</span>
      </div>
      <div class="data-container">
        <span>G6</span>
        <span>PC2823073</span>
        <span>H.H</span>
        <span>340591 08 SP1</span>
        <span></span>
        <span>2020-01-07 13:45:19</span>
        <span></span>
        <span></span>
        <span>0</span>
        <span>0 mm</span>
        <span>0 mm</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss" scoped>
@import '@/styles/_mixins';
.info-outer-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 480px;
  padding: 20px 60px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.15);

  .img {
    background: url('~@/assets/images/device1.png') no-repeat center/contain;
    text-shadow: #000 0 0 0.2em;
    height: 150px;
  }

  .inner-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    margin: 30px 0;

    .label-container {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      width: 50%;

      span {
        font-size: 14px;
        height: 20px;
        font-family: 'Montserrat Medium', 'Montserrat Regular', 'Montserrat';
        font-weight: 500;
        color: #333333;
        line-height: 20px;
      }
    }

    .data-container {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;

      span {
        font-size: 14px;
        height: 20px;
        font-family: 'Montserrat Medium', 'Montserrat Regular', 'Montserrat';
        font-weight: 500;
        color: #6e6e6e;
        line-height: 20px;
      }
    }
  }
}

@include breakpoint-desktop2() {
  .info-outer-container {
    width: 450px;
  }
}
@include breakpoint-desktop1() {
  .info-outer-container {
    width: 420px;
  }
}
@include breakpoint-laptop2 {
  .info-outer-container {
    width: 400px;
    padding: 20px 50px;
  }
}
@include breakpoint-laptop1 {
  .info-outer-container {
    width: 320px;
    padding: 20px 30px;

    .inner-container .label-container span {
      font-size: 13px;
    }
    .inner-container .data-container span {
      font-size: 13px;
    }
  }
}
@include breakpoint-phone-plus {
  .info-outer-container {
    min-width: 340px;
    padding: 20px 40px;

    .inner-container .label-container span {
      font-size: 12px;
    }
    .inner-container .data-container span {
      font-size: 12px;
    }
  }
}
@include breakpoint-phone {
  .info-outer-container {
    width: 320px;
    padding: 20px 35px;

    .inner-container .label-container span {
      font-size: 12px;
    }
    .inner-container .data-container span {
      font-size: 12px;
    }
  }
}
</style>

<template>
  <span :class="style">{{ level }}</span>
</template>
<script>
export default {
  props: ['level'],
  data() {
    return {
      levels: ['info', 'primary', 'success', 'danger', 'warning', 'inverse']
    }
  },
  created() {
    if (this.levels.indexOf(this.level) < 0) {
      console.error(
        'wrong "level" props for "LevelLabel" component: ',
        this.level
      )
    }
  },
  computed: {
    style() {
      return ['label', this.level]
    }
  }
}
</script>
<style lang="scss" scoped>
.label {
  height: 20px;
  width: 90px;
  border-radius: 30px;
  font-size: 12px;
  font-family: 'Arial Regular', 'Arial';
  line-height: normal;
  padding: 0px 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.success {
  background-color: #65c872;
  color: #ffffff;
}
.warning {
  background-color: #f0ad4e;
  color: #ffffff;
}
.danger {
  background-color: #d9534f;
  color: #ffffff;
}
.inverse {
  background-color: #292b2c;
  color: #ffffff;
}
.primary {
  background-color: #0275d8;
  color: #ffffff;
}
.info {
  background-color: #5bc0de;
  color: #ffffff;
}
</style>

<template>
  <div class="device-container">
    <div class="device-header">
      <div
        class="header-img"
        @click="$router.push({ path: '/device-detail' })"
      ></div>
      <div class="header-content">
        <label class="header-status">Machining</label>
        <span class="title">Application Center AC-103</span>
        <span class="detail">G6</span>
        <span class="detail">PC2823073</span>
        <span class="detail">H.H</span>
      </div>
    </div>
    <div class="line"></div>
    <div class="device-body">
      <span class="title">Status</span>
      <status></status>
    </div>
    <div class="line"></div>
    <div class="device-footer">
      <span class="title">transfer in process</span>
      <div class="footer-content">
        <span>2020-01-07 03:40:41 #19923356 Data</span>
        <span style="color: #4d7fff">
          <font-awesome-icon :icon="['fas', 'comment-dots']" size="lg" />1
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import Status from './Status'
export default {
  components: { Status }
}
</script>
<style lang="scss" scoped>
@import '@/styles/_mixins';

.device-container {
  display: flex;
  flex-direction: column;
  width: 350px;
  background-color: #ffffff;
  padding: 22px;
  border-radius: 10px;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.15);
  margin: 10px 10px;
  // border: 2px blue solid;
}
.device-header {
  display: flex;
  flex-direction: row;
  padding: 2px;

  .header-img {
    background: url('~@/assets/images/device1.png') no-repeat center/contain;
    display: flex;
    align-items: center;
    text-shadow: #000 0 0 0.2em;
    width: 50%;
    cursor: pointer;
  }
  .header-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 12px;
    width: 50%;

    .title {
      font-weight: 600;
      color: #333333;
      text-align: left;
      line-height: normal;
      font-size: 16px;
      margin-bottom: 5px;
      font-family: 'Montserrat SemiBold', 'Montserrat Regular', 'Montserrat';
    }

    .detail {
      font-weight: 500;
      color: #6e6e6e;
      text-align: left;
      line-height: normal;
      font-size: 14px;
      font-family: 'Roboto';
    }

    .header-status {
      width: 89px;
      height: 23px;
      border-radius: 30px;
      font-size: 12px;
      background-color: #65c872;
      box-sizing: border-box;
      font-family: 'Arial Regular', 'Arial';
      color: #ffffff;
      text-align: center;
      line-height: normal;
      padding: 5px;
    }
  }
}
.device-body {
  display: flex;
  flex-direction: column;
  // border: 2px green solid;

  .title {
    font-size: 12px;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: 'Montserrat Medium', 'Montserrat Regular', 'Montserrat';
    font-weight: 500;
    color: #333333;
    text-align: left;
    line-height: normal;
    margin-bottom: 5px;
  }
}
.device-footer {
  display: flex;
  flex-direction: column;

  .title {
    background-color: rgba(255, 255, 255, 0);
    font-family: 'Montserrat';
    color: #6e6e6e;
    line-height: normal;
    font-size: 12px;
  }
  .footer-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      margin-top: 5px;
      font-size: 10px;
      font-family: 'Arial Regular', 'Arial';
      color: #9d9d9d;
      line-height: normal;

      svg {
        margin-right: 3px;
      }
    }
  }
}
.line {
  width: 325px;
  height: 1px;
  border: 1px solid #f2f2f2;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial';
  color: #333333;
  text-align: center;
  line-height: normal;
  margin: 10px 0;
}
@include breakpoint-phone-plus {
  .device-container {
    width: 330px;
    padding: 12px;
  }
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Device from '@/views/Device/Device.vue'
import DeviceDetail from '@/views/DeviceDetail/DeviceDetail.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  }, {
    path: '/device',
    name: 'device',
    component: Device
  }, {
    path: '/device-detail',
    name: 'deviceDetail',
    component: DeviceDetail
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
